import React from 'react';
import PropTypes from 'prop-types';
import useId from '../private/hooks/useId';
import { FormController } from '../form/FormController';
import { FormLabel } from '../form/FormLabel';
import { FormHelpMessage } from '../form/FormHelpMessage';
import { FormStatusMessage } from '../form/FormStatusMessage';
import { Dropdown } from './Dropdown';

/**
 * `DropdownField` is a useful component that bundles `FormController`, `FormLabel`, `Dropdown`, `FormStatusMessage`
 * and `FormHelpMessage` components together for form handling.
 *
 * Use the `MenuItem` component for each available option.
 *
 * Related components: [MenuItem](#menuitem), [ListItemText](#listitemtext), [Dropdown](#dropdown),
 * [FormLabel](#formlabel), [FormController](#formcontroller), [FormHelpMessage](#formhelpmessage),
 * [FormStatusMessage](#formstatusmessage), [NativeDropdown](#nativedropdown), [DynamicDropdown](#dynamicdropdown)
 *
 * Usage:
 *
 * ```jsx
 * import { DropdownField } from '@one-thd/sui-atomic-components';
 * ```
 */
const DropdownField = (props) => {
  const {
    label,
    labelProps,
    dropdownProps,
    disabled,
    status,
    children,
    helpMessage,
    fullWidth,
    statusMessage,
    value,
    defaultValue,
    onChange,
    id: idProp
  } = props;

  const id = useId(idProp);
  const formLabelId = label && id ? `${id}-label` : undefined;
  const helpMessageId = helpMessage && id ? `${id}-help-message` : undefined;
  const statusMessageId = statusMessage && id ? `${id}-status-message` : undefined;
  const ariaDescribedby = [];
  if (statusMessageId) ariaDescribedby.push(statusMessageId);
  if (helpMessageId) ariaDescribedby.push(helpMessageId);

  return (
    <FormController disabled={disabled} status={status} fullWidth={fullWidth}>
      <FormLabel
        htmlFor={id}
        id={formLabelId}
        {...labelProps}
      >
        {label}
      </FormLabel>
      <Dropdown
        aria-describedby={ariaDescribedby.length ? ariaDescribedby.join(' ') : undefined}
        disabled={disabled}
        id={id}
        value={value}
        defaultValue={defaultValue}
        onChange={onChange}
        {...dropdownProps}
      >
        {children}
      </Dropdown>
      {statusMessage && (
        <FormStatusMessage id={statusMessageId}>
          {statusMessage}
        </FormStatusMessage>
      )}
      {helpMessage && (
        <FormHelpMessage id={helpMessageId}>
          {helpMessage}
        </FormHelpMessage>
      )}
    </FormController>
  );
};

DropdownField.displayName = 'DropdownField';

DropdownField.propTypes = {
  /**
   * If `true`, the component is inactive
   */
  disabled: PropTypes.bool,
  /**
   * The id attribute that is added to the DropdownField element
   */
  id: PropTypes.string,
  /**
   * The DropdownField label
   */
  label: PropTypes.string,
  /**
   * A list of `MenuItem` elements to populate the dropdown with.
   */
  children: PropTypes.node.isRequired,
  /**
   * If `true`, the component will take up the full width of its container.
   */
  fullWidth: PropTypes.bool,
  /**
   * The states of validation for the DropdownField component.
   */
  status: PropTypes.oneOf(['error', 'success', 'warning']),
  /**
   * The description for the TextField component.
   */
  helpMessage: PropTypes.string,
  /**
   * Adds a message when there is a status value.
   */
  statusMessage: PropTypes.string,
  /**
   * The prop values that will be added to the FormLabel component.
   */
  labelProps: PropTypes.object,
  /**
   * Event fired when the user changes the selected list item.
   */
  onChange: PropTypes.func,
  /**
   * The prop values that will be added to the Dropdown component.
   */
  dropdownProps: PropTypes.object,
  /**
   * The value of the selected menu item.
   * Dropdown is considered controlled if, on initial render, value is not `undefined`.
   */
  value: PropTypes.node,
  /**
   * The default value. Use when the component is not controlled.
   */
  defaultValue: PropTypes.node
};

export { DropdownField };