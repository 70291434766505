import React, { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { element, func, shape, string } from 'prop-types';
import { FormProvider } from '../FormContext';

const Form = (props) => {
  const { onSubmit, defaultValues, children, className } = props;

  useEffect(() => {
    LIFE_CYCLE_EVENT_BUS.lifeCycle.trigger('form.ready');
  }, []);

  const [submitted, setSubmitted] = useState(false);

  const {
    control,
    formState: { errors },
    getValues,
    handleSubmit,
    setValue,
    trigger,
  } = useForm({
    defaultValues: defaultValues && defaultValues,
  });

  const formInfo = {
    control,
    errors,
    getValues,
    handleSubmit,
    setValue,
    trigger
  };

  return (
    <FormProvider formInfo={formInfo} submitted={submitted} setSubmitted={setSubmitted}>
      <form className={className || ''} onSubmit={handleSubmit(onSubmit)}>
        {children}
      </form>
    </FormProvider>
  );
};

Form.displayName = 'Form';

Form.propTypes = {
  children: element.isRequired,
  className: string,
  defaultValues: shape({}),
  onSubmit: func.isRequired
};

Form.defaultProps = {
  className: 'sui-grid',
  defaultValues: {}
};

export { Form };
