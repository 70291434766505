import React, { useEffect, useRef, useMemo, useState } from 'react';
import {
  string, node, arrayOf, oneOfType, shape, func, bool
} from 'prop-types';
import deepmerge from 'deepmerge';
import QueryContext from './QueryContext';
import { filterClient } from './dataModel/filterClient';
import { extend } from './dataModel/extend';
import { updateStorageAndClientCache } from './util';
import DataProviderClient from './DataProvider.client';

const walkChildrenTree = (children) => {
  return React.Children.toArray(children).map((child) => {
    if (child.type?.dataModel) {
      return child;
    }

    if (child.props?.originalType?.dataModel) {
      return child;
    }

    if (child.props?.children) {
      return walkChildrenTree(child.props.children);
    }
    return null;
  }).flat()
    .filter((val) => val);
};

export const getQueryAttributes = (children, asChildren = true) => {
  const childWrapper = asChildren ? walkChildrenTree(children) : children;
  const client = childWrapper.map((child) => {

    const type = (child.props?.originalType || child.type || {});
    return asChildren
      ? type.dataModel || {}
      : child;
  })
    .reduce((acc, cur) => {
      return deepmerge(acc, cur);
    }, {});

  const server = childWrapper.map((child) => {
    const type = (child.props?.originalType || child.type || {});
    return asChildren
      ? type.dataModel || {}
      : child;
  })
    .reduce((acc, cur) => {
      const filtered = filterClient(cur);
      return deepmerge(acc, filtered);
    }, {});

  return {
    client,
    server
  };
};

export const QueryProvider = ({
  dataSource,
  children,
  defaultVariables,
  mounted,
  skip,
  queryOptions,
  merge,
  cacheKey,
  persist,
  fusion,
  forceClientQueriesOnServer,
}) => {
  const dataStore = useRef({});
  const defaultVariablesRef = useRef({});
  const queryAttributeRef = useRef(null);
  const [persistReady, setPersistReady] = useState(false);

  if (defaultVariables) {
    defaultVariablesRef.current = defaultVariables;
  }

  // get all dataModels of children for client and server
  const queryAttributes = useMemo(() => {

    if (cacheKey
      && QueryProvider.cache[cacheKey]
      && Object.keys(QueryProvider.cache[cacheKey]).length) {
      return QueryProvider.cache[cacheKey];
    }
    if (queryAttributeRef.current) {
      return queryAttributeRef.current;
    }

    if (merge) {
      Object.keys(merge).forEach((key) => {
        const queries = merge[key].queries
          .map((query) => children.type.dataModel[query])
          .filter((val) => val);
        if (queries.length > 1) {
          // eslint-disable-next-line no-param-reassign
          children.type.dataModel[key] = extend(
            ...queries
          );
        }
      });
    }

    const attr = getQueryAttributes(children);
    queryAttributeRef.current = attr;
    if (cacheKey) {
      QueryProvider.cache[cacheKey] = attr;
    }

    return attr;
  }, [cacheKey]);

  const isClientResolved = ({ queryName }) => {
    const clientResolved = queryAttributes.client[queryName]?._isResolved;
    return !!clientResolved;
  };

  const setDefaultVariables = (props) => {
    defaultVariablesRef.current = {
      ...defaultVariables.current,
      ...props
    };
  };

  useEffect(() => {
    if (!persist) return;
    if (typeof window !== 'undefined') {
      updateStorageAndClientCache(DataProviderClient);
      setTimeout(() => {
        setPersistReady(true);
      }, 0);
    }
  }, []);

  const value = {
    dataSource,
    defaultVariables: defaultVariablesRef,
    queryAttributes,
    mounted,
    skip,
    isClientResolved,
    queryOptions,
    merge,
    cacheKey,
    persist: {
      hasPersist: persist,
      ready: persistReady,
    },
    setDefaultVariables,
    dataStore: fusion ? dataStore : null,
    forceClientQueriesOnServer,
  };

  return (
    <QueryContext.Provider value={value}>
      {children}
    </QueryContext.Provider>
  );
};

QueryProvider.propTypes = {
  children: oneOfType([
    node,
    arrayOf(node),
  ]).isRequired,
  cacheKey: string.isRequired,
  dataSource: string,
  defaultVariables: shape({}),
  mounted: func,
  skip: func,
  queryOptions: func,
  persist: bool,
  merge: shape({
    [string]: shape({
      queries: arrayOf(string),
      fn: func
    })
  }),
  fusion: bool,
  forceClientQueriesOnServer: arrayOf(string),
};

QueryProvider.defaultProps = {
  dataSource: '',
  defaultVariables: null,
  mounted: ({ mounted }) => mounted,
  persist: false,
  skip: ({ skip }) => skip,
  queryOptions: ({ options, queryName }) => options,
  merge: null,
  fusion: false,
  forceClientQueriesOnServer: [],
};
QueryProvider.cache = {};
