import React from 'react';
import { Button } from '@one-thd/sui-atomic-components';
import { bool, string } from 'prop-types';
import { useFormContext } from '../FormContext';

const FormButton = (props) => {
  const {
    label, variant, className, fullWidth, disabled
  } = props;

  const context = useFormContext();
  const { setSubmitted } = context;

  return (
    <div className={className}>
      <Button
        variant={variant}
        type="submit"
        fullWidth={fullWidth}
        onClick={() => setSubmitted(true)}
        role="button"
        disabled={disabled}
      >
        {label}
      </Button>
    </div>
  );
};

FormButton.displayName = 'FormButton';

FormButton.propTypes = {
  label: string.isRequired,
  variant: string.isRequired,
  fullWidth: bool,
  className: string,
  disabled: bool
};

FormButton.defaultProps = {
  className: '',
  fullWidth: false,
  disabled: false,
};

export { FormButton };
