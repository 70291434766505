import React from 'react';
import { Loader } from '@thd-olt-component-react/core-ui';

function LoadingContent() {
  return (
    <div className="sui-grid sui-grid-cols-1 sui-justify-items-center">
      <div className="sui-grid sui-grid-cols-1 sui-m-20 sui-justify-items-center">
        <Loader className="sui-grid sui-grid-cols-1 sui-justify-items-center" active />
      </div>
    </div>
  );
}

export default LoadingContent;
