import React, { useEffect } from 'react';
import { useHelmet, TitleTag, MetaTag, LinkTag } from '@thd-nucleus/thd-helmet';

const HD_URL = 'https://www.homedepot.com';

const workshopsMetaDataHandler = ({ props }) => {
  const tags = [];
  const {
    alternateUrlEnCa = '',
    alternateUrlFrCa = '',
    alternateUrlEsMx = '',
    data,
    description = '',
    title = ''
  } = props;
  const dataKeys = data && Object.keys(data);

  if (alternateUrlEnCa) {
    tags.push(new LinkTag({
      rel: 'alternate',
      href: alternateUrlEnCa,
      hreflang: 'en-ca',
      id: 'alternate-en-ca'
    }));
  }
  if (alternateUrlFrCa) {
    tags.push(new LinkTag({
      rel: 'alternate',
      href: alternateUrlFrCa,
      hreflang: 'fr-ca',
      id: 'alternate-fr-ca'
    }));
  }
  if (alternateUrlEsMx) {
    tags.push(new LinkTag({
      rel: 'alternate',
      href: alternateUrlEsMx,
      hreflang: 'es-mx',
      id: 'alternate-es-mx'
    }));
  }

  if (description) tags.push(new MetaTag({ name: 'description', content: description, id: 'prop-description' }));
  if (title) tags.push(new TitleTag(title));

  if (!dataKeys?.length) return tags;

  dataKeys.forEach((tag) => {
    const content = data[tag] || '';

    if (tag === '__typename') {
      return;
    }
    if (tag === 'canonicalURL') {
      tags.push(new LinkTag({ rel: 'alternate', href: `${HD_URL}${content}`, hreflang: 'en', id: 'alternate-en' }));
      tags.push(new MetaTag({ property: 'og:url', content: `${HD_URL}${content}`, id: tag }));
    } else if (tag === 'siteName') {
      tags.push(new MetaTag({ property: 'og:site_name', content, id: tag }));
    } else if (tag === 'fbAdmins') {
      tags.push(new MetaTag({ property: 'fb:admins', content, id: tag }));
    } else {
      tags.push(new MetaTag({ property: `og:${tag}`, content, id: tag }));
    }
  });

  if (data.title) tags.push(new TitleTag(data.title));
  if (data.description) {
    tags.push(new MetaTag({ name: 'description', content: data.description || '', id: 'data-description' }));
  }
  if (data.canonicalURL) {
    tags.push(new LinkTag({ rel: 'canonical', href: `${HD_URL}${data.canonicalURL || ''}`, id: 'canonical' }));
  }

  return tags;
};

const WorkshopsMetaData = (props) => {
  useHelmet('workshopsMetadata', { props }, workshopsMetaDataHandler, [props]);

  useEffect(() => {
    LIFE_CYCLE_EVENT_BUS.lifeCycle.trigger('workshops-metadata.ready');
  }, []);

  return null;
};

export { WorkshopsMetaData };
