export const MAX_PARTICIPANTS = 4;

export const OTHER_OPT = 'Other, please specify below';

export const HEAR_ABOUT_US_OPTIONS = [
  'Store Associate',
  'Store Signage',
  'Friend / Family Member',
  'Social Media - Facebook',
  'Social Media - Instagram',
  'Social Media - Nextdoor',
  'Digital Audio / Radio',
  OTHER_OPT,
];