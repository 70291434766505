import React from 'react';
import {
  bool, func, instanceOf, oneOfType, shape, string
} from 'prop-types';
import { FormBaseTextFields } from './FormBaseTextFields';

const FormTextField = (props) => {
  const {
    name,
    defaultValue,
    label,
    onChange,
    onValidate,
    optional,
    placeholder,
    required,
    validate
  } = props;

  return (
    <FormBaseTextFields
      name={name}
      defaultValue={defaultValue}
      label={label}
      placeholder={placeholder}
      required={required}
      onChange={onChange}
      onValidate={onValidate}
      optional={optional}
      validate={validate}
      type="text"
    />
  );
};

FormTextField.displayName = 'FormTextField';

FormTextField.propTypes = {
  name: string.isRequired,
  defaultValue: string,
  label: string,
  onChange: func,
  onValidate: oneOfType([
    func,
    shape({})
  ]),
  optional: bool,
  placeholder: string,
  required: bool,
  validate: shape({}),
};

FormTextField.defaultProps = {
  defaultValue: '',
  label: 'name',
  onChange: null,
  onValidate: null,
  optional: false,
  placeholder: 'name',
  required: false,
  validate: null
};

export { FormTextField };
