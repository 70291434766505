/* eslint-disable react/forbid-prop-types */
/* eslint-disable max-len */
import React from 'react';
import { Profile, Calendar, Location, Clock } from '@one-thd/sui-icons';
import { Button, Typography, ModalBody, ModalFooter } from '@one-thd/sui-atomic-components';
import { object, func } from 'prop-types';

function SuccessContent({ registrationData, modalOnClose, setModalPage }) {
  const formatDate = (dateStr) => {
    const date = new Date(Date.parse(dateStr));
    const year = date.getFullYear();
    const month = date.toLocaleString('default', { month: 'long' });
    const day = date.getDate();
    return `${month} ${day}, ${year}`;
  };

  return (
    <div>
      <ModalBody>
        <div className="sui-mt-6 sui-grid sui-grid-cols-1 sui-gap-4">
          <Typography variant="body-lg" weight="regular" height="tight">
            <span className="sui-text-brand sui-mr-4">
              <Profile color="black" />
            </span>
            {registrationData?.numOfParticipants} Kid Doers
          </Typography>

          <Typography variant="body-lg" weight="regular" height="tight">
            <span className="sui-text-brand sui-mr-4">
              <Calendar color="black" />
            </span>
            {formatDate(registrationData?.date)}
          </Typography>

          <Typography variant="body-lg" weight="regular" height="tight">
            <span className="sui-text-brand sui-mr-4">
              <Location color="black" />
            </span>
            {registrationData?.storeName} #{registrationData?.storeId}
            <br />
            <span className="sui-ml-10">{registrationData?.address?.street}</span>
            <br />
            <span className="sui-ml-10">
              {registrationData?.address?.city}, {registrationData?.address?.state},{' '}
              {registrationData?.address?.postalCode}
            </span>
          </Typography>

          <Typography variant="body-lg" weight="regular" height="tight">
            <span className="sui-text-brand sui-mr-4">
              <Clock color="black" />
            </span>
            Any time from 9 am - 12 pm
          </Typography>
          <Typography variant="body-lg" weight="regular" height="tight">
            You should receive an email confirmation soon with more details.
            <br />
          </Typography>
        </div>
      </ModalBody>
      <ModalFooter position="end">
        <Button
          variant="primary"
          onClick={() => {
            modalOnClose();
            setModalPage('form');
          }}
        >
          Finished
        </Button>
      </ModalFooter>
    </div>
  );
}

SuccessContent.propTypes = {
  registrationData: object.isRequired,
  modalOnClose: func.isRequired,
  setModalPage: func.isRequired,
};

export default SuccessContent;