import { Tag } from './Tag';

class TitleTag extends Tag {
  constructor(text) {
    super();
    this.type = 'title';
    const cleaned = text.replace(/</g, '&lt;').replace(/>/g, '&gt;');
    this.text = cleaned;
  }

  updateDom() {
    let titleTag = document.head.querySelector('title');
    if (!titleTag) {
      titleTag = document.createElement('title');
      document.head.appendChild(titleTag);
    }
    titleTag.innerText = this.text;
  }

  // eslint-disable-next-line class-methods-use-this
  removeFromDom() {
    const el = document.head.querySelector('title');
    if (el && el.remove) {
      el.remove();
    }
  }

  toString() {
    return `<title data-th="server">${this.text}</title>`;
  }
}

export { TitleTag };
