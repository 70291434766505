import React from 'react';
import { Controller } from 'react-hook-form';
import { DropdownField, MenuItem } from '@one-thd/sui-atomic-components';
import {
  array, bool, func, number, oneOfType, shape, string
} from 'prop-types';
import { useFormContext } from '../FormContext';

const FormDropdown = (props) => {
  const {
    name,
    defaultValue,
    label,
    options,
    required,
    trigger,
    onChange
  } = props;

  const context = useFormContext();
  const {
    control,
    errors,
    getValues,
    setValue,
    submitted
  } = context;

  return (
    <Controller
      name={name}
      control={control}
      render={() => (
        <DropdownField
          label={label}
          onChange={(val) => {
            if (onChange) {
              onChange(val);
            }
            setValue(name, val);
          }}
          required={required}
          /* eslint-disable */
          status={errors?.[name]?.message && !getValues(name) ? "error" : submitted ? "success" : null}
          statusMessage={errors?.[name]?.message && !getValues(name) ? errors[name].message : ""}
        >
          {options.map((value, index) => {
            return (
              <MenuItem key={index} value={value.value}>
                {value.label}
              </MenuItem >
            );
          })}
        </DropdownField>
      )}
      defaultValue={defaultValue}
      rules={{
        required: required ? "Select one option" : null
      }}
    />
  );
};

FormDropdown.displayName = "FormDropdown";

FormDropdown.propTypes = {
  name: string.isRequired,
  defaultValue: oneOfType([
    string,
    number,
    shape({})
  ]),
  label: string,
  options: array.isRequired,
  required: bool,
  trigger: func,
  onChange: func
};

FormDropdown.defaultProps = {
  defaultValue: null,
  label: "Dropdown",
  required: false,
  trigger: ()=>{},
};

export { FormDropdown };
