import React, { useContext, useEffect, useState } from 'react';
import classNames from 'classnames/bind';
import { bool, string } from 'prop-types';
import { withHydrator } from '@thd-olt-component-react/hydrator';
import { withDynamicComponent } from '@thd-nucleus/app-render';
import { Placeholder } from '@thd-olt-component-react/core-ui';
// eslint-disable-next-line import/no-extraneous-dependencies
import {
  Button, Card, CardBody, CardMedia, CardTitle
} from '@one-thd/sui-atomic-components';
import { ExperienceContext, useStore } from '@thd-nucleus/experience-context';
import styles from '../styles/workshops-card.module.scss';
import { RegistrationModal } from './RegistrationModal';

const WorkshopsCardComponent = ({
  name,
  imageAlt,
  imagePath,
  callToActionButtonText,
  callToActionUrl,
  description,
  registrationFlag,
}) => {
  const experienceContext = useContext(ExperienceContext);
  const { customer } = experienceContext;
  const [modalOpen, setModalOpen] = useState(false);
  const store = useStore({ varnish: false });

  useEffect(() => {
    LIFE_CYCLE_EVENT_BUS.lifeCycle.trigger('workshops-card.ready');
  }, []);

  const onWorkshopsCtaClick = (event, { url }) => {
    event.preventDefault();
    window.open(url, '_blank');
  };

  const openRegisterModal = () => {
    setModalOpen(true);
  };

  const closeRegisterModal = () => {
    setModalOpen(false);
  };

  return (
    <>
      <RegistrationModal
        modalOpen={modalOpen}
        oldStoreId={store?.storeId}
        modalOnClose={closeRegisterModal}
        svocId={customer.svocId}
      />
      <Card component="article">
        <CardMedia src={imagePath} alt={imageAlt} aspect="wide" />
        <CardTitle header={name} />
        <CardBody>{description}</CardBody>
        {registrationFlag && !store?.storeId ? <span>To register for a Kids Workshop, please select your store</span> : (
          <Button
            variant="secondary"
            onClick={
              registrationFlag
                ? openRegisterModal
                : (event) => onWorkshopsCtaClick(event, { url: callToActionUrl })
            }
            fullWidth
          >
            {callToActionButtonText}
          </Button>
        )}
      </Card>
    </>
  );
};

WorkshopsCardComponent.propTypes = {
  name: string.isRequired,
  imageAlt: string.isRequired,
  imagePath: string.isRequired,
  callToActionButtonText: string.isRequired,
  // eslint-disable-next-line react/require-default-props
  callToActionUrl: string,
  description: string.isRequired,
  registrationFlag: bool.isRequired,
};

WorkshopsCardComponent.displayName = 'WorkshopsCard';

const HydratedComponent = withHydrator(
  {
    delay: 100,
    id: classNames(styles['workshops-card']),
    placeholder: (
      <Placeholder height="450px" width="100%" type="rect">
        <div />
      </Placeholder>
    ),
    scrollBuffer: 100,
  },
  WorkshopsCardComponent
);

const DynamicComponent = withDynamicComponent(HydratedComponent);
/** Generic component for displaying Workshops Cards with CTA buttons */
const WorkshopsCard = DynamicComponent;

export { WorkshopsCard };
