import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import useFormController from '../hooks/useFormController';
import formControllerState from '../utils/formControllerState';
import { TextArea } from '../text-area/TextArea';
import { Checkmark } from '../private/icons/Checkmark';
import { ErrorFilled } from '../private/icons/ErrorFilled';
import { WarningFilled } from '../private/icons/WarningFilled';

const InputBase = React.forwardRef((props, ref) => {
  const {
    'aria-describedby': ariaDescribedby,
    autoComplete,
    autoFocus,
    classes,
    components = {},
    defaultValue,
    disableStatus,
    endAdornment,
    fullWidth,
    id,
    inputAttributes,
    inputProps: inputPropsProp = {},
    inputRef,
    maxRows,
    minRows,
    multiline = false,
    name,
    onChange,
    onKeyDown,
    placeholder,
    readOnly,
    startAdornment,
    type = 'text',
    value,
    ...other
  } = props;

  const formControl = useFormController();

  const fcs = formControllerState({
    props,
    formControl,
    states: ['disabled', 'required', 'status', 'fullWidth']
  });

  const handleChange = (event, ...args) => {
    if (onChange) {
      onChange(event, ...args);
    }
  };

  const Root = components.Root || 'div';
  let Input = components.Input || 'input';

  let inputProps = inputPropsProp;

  if (multiline && Input === 'input') {
    inputProps = {
      type: undefined,
      maxRows,
      minRows,
      ...inputProps,
    };

    Input = TextArea;
  }

  const statusIcon = React.useMemo(() => {
    let icon;
    if (disableStatus) return icon;

    if (fcs.status === 'success') {
      icon = (
        <span className={fcs.disabled ? 'sui-fill-subtle' : 'sui-fill-success-strong'} style={{ display: 'inherit' }}>
          <Checkmark size="small" color="inherit" />
        </span>
      );
    }
    if (fcs.status === 'error') {
      icon = (
        <span className={fcs.disabled ? 'sui-fill-subtle' : 'sui-fill-danger-strong'} style={{ display: 'inherit' }}>
          <ErrorFilled size="small" color="inherit" />
        </span>
      );
    }
    if (fcs.status === 'warning') {
      icon = (
        <span className={fcs.disabled ? 'sui-fill-subtle' : 'sui-fill-warning-strong'} style={{ display: 'inherit' }}>
          <WarningFilled size="small" color="inherit" />
        </span>
      );
    }
    return icon;
  }, [disableStatus, fcs.status, fcs.disabled]);

  const isStartAdornment = startAdornment || false;
  const isEndAdornment = endAdornment || statusIcon || false;

  const rootClasses = classNames('sui-input-base-root', {
    'sui-w-full': fcs.fullWidth,
    'sui-bg-primary sui-text-primary': !fcs.disabled,
    'sui-bg-inactive sui-text-inactive sui-cursor-default sui-pointer-events-none': fcs.disabled,
    'sui-pl-3': isStartAdornment,
    'sui-pr-3': isEndAdornment
  }, classes?.root);

  const inputClasses = classNames('sui-input-base-input sui-peer', {
    'sui-pl-3': !isStartAdornment,
    'sui-pr-3': !isEndAdornment,
    'sui-pl-0 sui-pr-3': isStartAdornment && !isEndAdornment,
    'sui-pl-3 sui-pr-0': isEndAdornment && !isStartAdornment
  }, classes?.input);

  const outlineClasses = classNames('sui-input-base-outline', {
    'peer-hover:sui-border-input-hover peer-focus:sui-border-input-hover': !fcs.status && !fcs.disabled,
    'sui-border-success-strong': !fcs.disabled && fcs.status === 'success',
    'sui-border-danger-strong': !fcs.disabled && fcs.status === 'error',
    'sui-border-warning-strong': !fcs.disabled && fcs.status === 'warning',
    'sui-border-input-inactive': fcs.disabled
  }, classes?.outline);

  return (
    <Root
      ref={ref}
      {...other}
      className={rootClasses}
    >
      {startAdornment}
      <Input
        aria-invalid={fcs.status === 'error'}
        aria-describedby={ariaDescribedby}
        autoComplete={autoComplete}
        autoFocus={autoFocus}
        className={inputClasses}
        defaultValue={defaultValue}
        disabled={fcs.disabled}
        id={id}
        name={name}
        onChange={handleChange}
        onKeyDown={onKeyDown}
        placeholder={placeholder}
        readOnly={readOnly}
        required={fcs.required}
        value={value}
        type={type}
        style={{ font: 'inherit', letterSpacing: 'inherit', background: 'none' }}
        {...inputAttributes}
        {...inputProps}
        ref={inputRef}
      />
      {statusIcon || endAdornment}
      <div className={outlineClasses} />
    </Root>
  );
});

InputBase.displayName = 'InputBase';

InputBase.propTypes = {
  /**
   * @ignore
   */
  'aria-describedby': PropTypes.string,
  /**
   * Identifies the element (or elements) that describes the element on which the attribute is set.
   */
  ariaDescribedby: PropTypes.string,
  /**
   * If "on", when a user starts to type in a field, the browser should display options to fill
   * in the field, based on earlier typed values.
   */
  autoComplete: PropTypes.string,
  /**
   * @ignore
   */
  autoFocus: PropTypes.bool,
  /**
   * Override or extend the styles applied to the component.
   */
  classes: PropTypes.object,
  /**
   * The components used for each slot inside the InputBase.
   * Either a string to use a HTML element or a component.
   * @default {}
   */
  components: PropTypes.shape({
    Input: PropTypes.elementType,
    Root: PropTypes.elementType,
  }),
  /**
   * The default value for the input element.
   */
  defaultValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  /**
   * If `true`, the component is disabled.
   */
  disabled: PropTypes.bool,
  /**
   * If `true`, the status icons are disabled.
   */
  disableStatus: PropTypes.bool,
  /**
   * End `InputAdornment` for component.
   */
  endAdornment: PropTypes.node,
  /**
   * If `true` will make the component take the full width available.
   */
  fullWidth: PropTypes.bool,
  /**
   * The id attribute that is added to the input element
   */
  id: PropTypes.string,
  /**
   * [Attributes](https://developer.mozilla.org/en-US/docs/Web/HTML/Element/input#Attributes)
   * applied to the `input` element.
   */
  inputAttributes: PropTypes.object,
  /**
   * Props applied to the `input` element
   */
  inputProps: PropTypes.object,
  /**
   * Pass a ref to the `input` element.
   */
  inputRef: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
  /**
   * If the component is a textarea, this will determine minimum number of rows.
   */
  minRows: PropTypes.number,
  /**
   * If the component is a textarea, this will determine maximum number of rows.
   */
  maxRows: PropTypes.number,
  /**
   * If `true`, the `input` component will transform into a textarea.
   * @default false
   */
  multiline: PropTypes.bool,
  /**
   * The name attribute that is added to the input element.
   */
  name: PropTypes.string,
  /**
   * The onChange attribute that is added to the input element.
   */
  onChange: PropTypes.func,
  /**
   * @ignore
   */
  onKeyDown: PropTypes.func,
  /**
   * The placeholder attribute that is added to the input element.
   */
  placeholder: PropTypes.string,
  /**
   * Prevents the user from changing the value of the input
   * (not from interacting with the field).
   */
  readOnly: PropTypes.bool,
  /**
   * Start `InputAdornment` for this component.
   */
  startAdornment: PropTypes.node,
  /**
   * The states of validation for the Input component.
   */
  status: PropTypes.oneOf(['error', 'success', 'warning']),
  /**
   * The type attribute that is added to the input element.
   * @default 'text'
   */
  type: PropTypes.string,
  /**
   * The value attribute that is added to the input element.
   */
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
};

export { InputBase };
