import React, { createContext, useContext } from 'react';
import { bool, element, func, shape } from 'prop-types';

const FormContext = createContext();

const FormProvider = ({ children, formInfo, setSubmitted, submitted }) => {
  const {
    control,
    errors,
    getValues,
    handleSubmit,
    setValue,
    trigger
  } = formInfo;

  const values = {
    control,
    errors,
    getValues,
    handleSubmit,
    setValue,
    trigger,
    setSubmitted,
    submitted
  };

  return (
    <FormContext.Provider value={values}>
      {children}
    </FormContext.Provider>
  );
};

FormProvider.propTypes = {
  children: element.isRequired,
  formInfo: shape({}).isRequired,
  setSubmitted: func,
  submitted: bool
};

FormProvider.defaultProps = {
  setSubmitted: null,
  submitted: false
};

const useFormContext = () => {
  const context = useContext(FormContext);
  return context;
};

export {
  FormProvider,
  useFormContext
};
