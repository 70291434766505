import React, {
  useCallback, useEffect, useMemo, useState
} from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { ButtonBase } from '../button/ButtonBase';
import { areEqualValues } from '../utils';
import useFormController from '../hooks/useFormController';
import useControlled from '../private/hooks/useControlled';
import formControllerState from '../utils/formControllerState';
import { ArrowDown } from '../private/icons/ArrowDown';
import useId from '../private/hooks/useId';
import { Menu } from '../menu/Menu';

/**
 * `Dropdown` menu presents a list of options from which a user can select one option from a collapsible menu list.
 *
 * Use the `MenuItem` component for each available option.
 *
 * Related components: [MenuItem](#menuitem), [ListItemText](#listitemtext), [DropdownField](#dropdownfield),
 * [NativeDropdown](#nativedropdown), [DynamicDropdown](#dynamicdropdown), [FormLabel](#formlabel),
 * [FormController](#formcontroller), [Tile](#tile)
 *
 * Usage:
 *
 * ```jsx
 * import { Dropdown } from '@one-thd/sui-atomic-components';
 * ```
 */
const Dropdown = (props) => {

  const {
    disabled,
    disableMenuCloseOnScroll = false,
    placeholder = 'Select an Option',
    children,
    value: valueProp,
    show: showProp,
    defaultValue: defaultValueProp,
    defaultOpen,
    onChange,
    onClose,
    onOpen,
    maxItems = 5,
    menuProps = {},
    MenuProps: MenuPropsProp = {},
    id,
    ...rest
  } = props;

  const [value, setValue] = useControlled({
    controlled: valueProp,
    defaultValue: defaultValueProp
  });
  const [show, setShow] = useControlled({
    controlled: showProp,
    defaultValue: defaultOpen
  });

  const childrenArray = React.Children.toArray(children);
  const selectedIndex = childrenArray.findIndex((child) => (areEqualValues(value, child.props.value)));
  const itemSelected = selectedIndex > -1;
  const selectedItem = itemSelected ? childrenArray[selectedIndex] : undefined;

  const [isMenuTopPlaced, setIsMenuTopPlaced] = useState(false);
  const [label, setLabel] = useState(selectedItem?.props?.children || null);
  const [descendantId, setDescendantId] = useState(null);

  const MenuProps = {
    ...menuProps,
    ...MenuPropsProp
  };

  const menuId = useId(MenuProps?.id);

  const update = (open, event) => {
    if (open) {
      setShow(true);
      if (onOpen) {
        onOpen(event);
      }
    } else {
      setShow(false);
      if (onClose) {
        onClose(event);
      }
    }
  };

  const onClickHandler = (event) => {
    update(!show, event);
  };

  const keyDownHandler = (event) => {
    const key = event.key;
    const openingKeys = ['ArrowDown', 'ArrowUp', 'Home', 'End'];
    const isOpeningKey = openingKeys.indexOf(key) > -1;
    if (!show && isOpeningKey) {
      event.preventDefault();
      update(true, event);
    }
  };

  const onMenuListKeydownHandler = (event) => {
    const key = event.key;
    if (show && (key === 'Escape' || key === 'Tab')) {
      event.stopPropagation();
      update(false, event);
    }
  };

  const handleItemClick = (child) => (event) => {
    let newValue;

    newValue = child.props.value;

    if (child.props.onClick) {
      child.props.onClick(event);
    }

    if (value !== newValue) {
      setValue(newValue);

      if (onChange) {
        const nativeEvent = event.nativeEvent || event;
        const clonedEvent = new nativeEvent.constructor(nativeEvent.type, nativeEvent);

        Object.defineProperty(clonedEvent, 'target', {
          writable: true,
          value: { value: newValue },
        });
        onChange(clonedEvent, newValue, child);
      }
    }

    update(false, event);
  };

  const anchorRef = React.useRef(null);
  const handleClose = (event) => {
    update(false, event);

    if (event) {
      event.preventDefault();
    }
  };

  const formControl = useFormController();
  const fcs = formControllerState({
    props,
    formControl,
    states: ['disabled', 'required', 'status']
  });

  const onMenuPlacedHandler = (placement) => {
    setIsMenuTopPlaced(placement.startsWith('top'));
  };

  const buttonStylesMap = {
    base: 'sui-group sui-inline-flex sui-items-center sui-px-3 sui-py-2 sui-justify-between sui-font-regular sui-text-base sui-leading-tight sui-h-11 sui-w-full sui-rounded-base sui-border-solid sui-border-1',
    focus: 'focus-visible:sui-text-inverse focus-visible:sui-bg-inverse focus-visible:sui-outline focus-visible:sui-outline-4 focus-visible:sui-outline-input-focus'
  };

  const buttonClasses = classNames(`${buttonStylesMap.base} ${buttonStylesMap.focus}`,
    {
      'sui-text-inactive sui-bg-button-inactive-primary sui-border-primary sui-cursor-default': fcs.disabled,
      'sui-border-strong': !show && !fcs.status && !fcs.disabled,
      'sui-border-strongest': show && !fcs.status && !fcs.disabled,
      'hover:sui-border-input-hover': !fcs.status && !fcs.disabled,
      'sui-border-warning-strong': fcs.status === 'warning' && !fcs.disabled,
      'sui-border-danger-strong': fcs.status === 'error' && !fcs.disabled,
      'sui-border-success-strong': fcs.status === 'success' && !fcs.disabled,
      'sui-rounded-t-none': show && isMenuTopPlaced,
      'sui-rounded-b-none': show && !isMenuTopPlaced,
      'sui-text-subtle': !itemSelected && !fcs.disabled,
      'sui-text-primary': itemSelected && !fcs.disabled,
    },
  );

  const spanIconClasses = classNames('sui-flex sui-ml-4 sui-transition-transform sui-duration-250', {
    'group-focus-visible:sui-fill-inverse group-hover:sui-fill-brand': !fcs.disabled,
    'sui-fill-subtle': fcs.disabled,
    'sui-fill-primary': !show && !fcs.disabled,
    'sui-rotate-180 sui-fill-brand': show,
  });

  const childRefs = useMemo(() => ({}), []);
  const items = () => childrenArray.map((child, index) => {
    if (!React.isValidElement(child)) {
      return null;
    }
    const ref = React.createRef();
    childRefs[child.props.value] = ref;
    const selected = selectedItem === child;
    return React.cloneElement(child, {
      ref,
      selected,
      'aria-selected': selected ? 'true' : 'false',
      onClick: handleItemClick(child),
      role: 'option',
      id: child.props.id || menuId + '-' + child.props.value,
      value: undefined,
      'data-value': child.props.value
    });
  });

  useEffect(() => {
    if (selectedItem) {
      const itemChildren = selectedItem.props?.children;
      const labelProp = itemChildren.type?.labelProp;
      if (typeof labelProp === 'string') {
        setLabel(itemChildren.props[labelProp]);
      } else {
        setLabel(itemChildren);
      }
    }
  }, [selectedItem]);

  useEffect(() => {
    const selectedRef = childRefs[value];
    if (selectedItem && selectedRef && selectedRef.current !== null) {
      setDescendantId(selectedRef.current.id);
    }
  }, [selectedItem, value, childRefs]);

  const [menuStyle, setMenuStyle] = useState({});
  const menuListRef = useCallback((domNode) => {
    if (domNode) {
      const domRect = domNode.getBoundingClientRect();
      const validChildren = React.Children.toArray(children).filter((child) => React.isValidElement(child)).length;
      const showScrollbar = validChildren > maxItems;
      const maxHeight = validChildren === 0 ? 0 : (domRect.height / validChildren) * maxItems;
      setMenuStyle(showScrollbar ? { maxHeight: `${maxHeight}px`, overflowY: 'auto' } : {});
    }
  }, [children, maxItems]);

  return (
    <>
      <ButtonBase
        ref={anchorRef}
        id={id}
        onClick={onClickHandler}
        onKeyDown={keyDownHandler}
        disabled={fcs.disabled}
        role="combobox"
        aria-disabled={fcs.disabled}
        aria-expanded={show}
        aria-haspopup="listbox"
        aria-controls={menuId}
        aria-activedescendant={descendantId}
        className={buttonClasses}
        unstyled
        {...rest}
      >
        <span className="sui-text-ellipsis sui-overflow-hidden sui-whitespace-nowrap">
          {label || placeholder || <span>&nbsp;</span>}
        </span>
        <span className={spanIconClasses}>
          <ArrowDown size="small" color="inherit" />
        </span>
      </ButtonBase>
      <Menu
        open={show}
        anchorEl={anchorRef.current}
        onMenuPlaced={onMenuPlacedHandler}
        matchAnchorWidth
        onClose={handleClose}
        onScroll={disableMenuCloseOnScroll ? undefined : handleClose}
        style={menuStyle}
        autoFocus={defaultOpen === undefined}
        {...MenuProps}
        MenuListProps={{
          id: menuId,
          ref: menuListRef,
          onKeyDown: onMenuListKeydownHandler,
          ...MenuProps.MenuListProps,
        }}
        PaperProps={{
          className: classNames('sui-z-20 sui-border-x-strong sui-border-b-strong', {
            'sui-border-t-strong': !fcs.status,
            'sui-rounded-t-base': isMenuTopPlaced,
            'sui-rounded-b-base': !isMenuTopPlaced,
            'sui-shadow-md': !isMenuTopPlaced,
            'sui-border-t-warning-strong': fcs.status === 'warning',
            'sui-border-t-danger-strong': fcs.status === 'error',
            'sui-border-t-success-strong': fcs.status === 'success',
          })
        }}
      >
        {items()}
      </Menu>
    </>
  );
};

Dropdown.displayName = 'Dropdown';

Dropdown.propTypes = {
  /**
   * A list of `MenuItem` elements to populate the dropdown with.
   */
  children: PropTypes.node,
  /**
   * Text to show when no value is selected.
   * @default 'Select an Option'
   */
  placeholder: PropTypes.string,
  /**
   * Event fired when the user closes the menu list.
   */
  onClose: PropTypes.func,
  /**
   * Event fired when the user opens the menu list.
   */
  onOpen: PropTypes.func,
  /**
   * Event fired when the user changes the selected list item.
   */
  onChange: PropTypes.func,
  /**
   * If `true`, the component is inactive
   */
  disabled: PropTypes.bool,
  /**
   * If `true`, the menu will not close on page scroll.
   * @default false
   */
  disableMenuCloseOnScroll: PropTypes.bool,
  /**
   * The value of the selected menu item.
   * Dropdown is considered controlled if, on initial render, value is not `undefined`.
   */
  value: PropTypes.node,
  /**
   * The id attribute that is added to the Dropdown element
   */
  id: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]),
  /**
   * The default value for the Dropdown element. Use when the component is not controlled.
   */
  defaultValue: PropTypes.node,
  /**
   * The default open state Dropdown element. Use when the component is not controlled.
   */
  defaultOpen: PropTypes.bool,
  /**
   * The maximum number of items to show before showing a scrollbar.
   * If there are more than maxItems, the maxHeight of the list will be calculated by averaging the MenuItem's height
   * @default 5
   */
  maxItems: PropTypes.number,
  /**
   * If `true`, the Menu List component is shown.
   */
  show: PropTypes.bool,
  /**
   * The prop values that will be added to the Menu component.
   *
   * This prop is an alias for the `MenuProps` prop.
   * It's recommended to use the `MenuProps` prop instead, as `menuProps` will be deprecated in the future.
   */
  menuProps: PropTypes.object,
  /**
   * The props used for the Menu component.
   * @default {}
   */
  MenuProps: PropTypes.object
};

Dropdown.defaultProps = {};

export { Dropdown };