import { Cap } from './Cap';

export const readUploadFile = (oldStoreId, month) => {
  let storeId = oldStoreId;
  if (oldStoreId.length === 3) {
    storeId = '0' + oldStoreId;
  }
  const row = Cap.find((store) => store.store.toString() === storeId.toString());
  return row[month];
};
