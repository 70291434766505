import React from 'react';
import {
  bool, func, number, oneOfType, shape, string
} from 'prop-types';
import { FormBaseTextFields } from './FormBaseTextFields';

const FormNumber = (props) => {
  const {
    name, defaultValue, label, max, min, onChange, onValidate, optional, placeholder, required
  } = props;

  return (
    <FormBaseTextFields
      name={name}
      defaultValue={defaultValue}
      label={label}
      max={max}
      min={min}
      onChange={onChange}
      onValidate={onValidate}
      optional={optional}
      placeholder={placeholder}
      required={required}
      type="number"
    />
  );
};

FormNumber.displayName = 'FormNumber';

FormNumber.propTypes = {
  name: string.isRequired,
  defaultValue: oneOfType([
    string,
    number
  ]),
  label: string,
  max: number,
  min: number,
  onChange: func,
  onValidate: oneOfType([
    func,
    shape({})
  ]),
  optional: bool,
  placeholder: string,
  required: bool,
};

FormNumber.defaultProps = {
  defaultValue: '',
  label: 'number',
  max: 100,
  min: 0,
  onChange: null,
  onValidate: null,
  optional: false,
  placeholder: 'number',
  required: false,
};

export { FormNumber };
