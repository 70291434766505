import React from 'react';
import { Controller } from 'react-hook-form';
import { TextField } from '@one-thd/sui-atomic-components';
import {
  bool, func, number, oneOfType, shape, string, instanceOf
} from 'prop-types';
import { useFormContext } from '../FormContext';

const FormBaseTextFields = (props) => {
  const {
    name,
    defaultValue,
    label,
    max,
    min,
    onChange,
    onValidate,
    optional,
    placeholder,
    required,
    type,
    validate
  } = props;

  const context = useFormContext();
  const {
    control,
    errors,
    submitted
  } = context;

  /* eslint-disable */
  const patternValue = type === "email" ? /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/
    : type === "password" ? /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/
      : type === "text" && validate ? validate.regex : ""

  /* eslint-disable */
  const patternMessage = type === "email" ? "A valid email address is required"
    : type === "password" ? "Minimum eight characters, at least one uppercase letter, one lowercase letter, one number and one special character."
      : type === "text" && validate ? validate.regexMessage : ""

  return (
    <Controller
      control={control}
      name={name}
      render={({ field }) => {
        return (
          <TextField
            label={label}
            onChange={(event) => {
              field.onChange(event)
              if (onChange) {
                onChange(event)
              }
            }}
            optional={optional}
            placeholder={placeholder}
            required={required}
            /* eslint-disable */
            status={
              errors?.[name]?.message 
              && (type === "number" || type === "password" || type === "email" || (!validate))
              ? "error" : errors?.[name]?.message && validate ? validate.typeError 
              : submitted && required ? "success" : null}
            statusMessage={errors?.[name]?.message && errors[name].message}
            type={type === "number" || type === "password" ? type : "text"}
            value={field.value}
          />
        )
      }}
      defaultValue={defaultValue}
      rules={{
        pattern: {
          message: patternMessage,
          value: patternValue
        },
        required: required ? "This field is required" : null,
        max: type === "number" ? {
          message: `The value has to be less than ${max}`,
          value: max
        } : null,
        min: type === "number" ? {
          message: `The value has to be more than ${min}`,
          value: min
        } : null,
        validate: onValidate
      }}
    />
  )
};

FormBaseTextFields.displayName = "FormBaseTextFields";

FormBaseTextFields.propTypes = {
  name: string.isRequired,
  defaultValue: oneOfType([
    bool,
    number,
    shape({}),
    string,
  ]),
  label: string,
  max: number,
  min: number,
  onChange: func,
  onValidate: oneOfType([
    func,
    shape({})
  ]),
  optional: bool,
  placeholder: string,
  required: bool,
  type: string.isRequired,
  validate:shape({})
};

FormBaseTextFields.defaultProps = {
  defaultValue: '',
  label: "text field",
  max: 100,
  min: 0,
  onChange: null,
  onValidate: null,
  optional: false,
  placeholder: "textField",
  required: false,
  validate:null
};

export { FormBaseTextFields };
