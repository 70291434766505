/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/require-default-props */
/* eslint-disable no-nested-ternary */
import { Typography, Modal, ModalHeader } from '@one-thd/sui-atomic-components';
import { useDataModel } from '@thd-nucleus/data-sources';
import React, { useState } from 'react';
import { bool, func, string as str } from 'prop-types';
import FormContent from './FormContent';
import SuccessContent from './SuccessContent';
import LoadingContent from './LoadingContent';

export const RegistrationModal = ({ modalOpen, modalOnClose, oldStoreId, svocId }) => {
  const [modalPage, setModalPage] = useState('form');
  const [registrationData, setRegistrationData] = useState({});
  let storeId = oldStoreId;
  if (storeId.length === 3) {
    storeId = '0' + storeId;
  }
  const { data } = useDataModel('workshops', {
    variables: { storeId },
  });

  const handleClose = () => {
    modalOnClose();
    setModalPage('form');
  };

  return (
    <Modal open={modalOpen} size="sm" onClose={handleClose}>
      <div>
        <ModalHeader onClose={handleClose}>
          <Typography variant="h3" weight="condensed">
            {modalPage === 'success' && 'You\'re Signed Up!'}
          </Typography>
        </ModalHeader>

        {modalPage === 'loading' ? (
          <LoadingContent />
        ) : modalPage === 'success' && registrationData ? (
          <SuccessContent
            registrationData={registrationData}
            setModalPage={setModalPage}
            modalOnClose={modalOnClose}
          />
        ) : modalPage === 'form' && modalOpen ? (
          <FormContent
            svocId={svocId}
            storeId={storeId}
            setModalPage={setModalPage}
            workshopsData={data}
            setRegistrationData={setRegistrationData}
          />
        ) : (
          ''
        )}
      </div>
    </Modal>
  );
};

RegistrationModal.displayName = 'RegistrationModal';
RegistrationModal.propTypes = {
  modalOpen: bool.isRequired,
  modalOnClose: func.isRequired,
  oldStoreId: str.isRequired,
  svocId: str,
};
