import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { ModalBase } from './ModalBase';
import { Fade } from '../private/components/fade/Fade';

const ModalRoot = React.forwardRef((props, ref) => {

  const {
    color,
    as: RootComponent = 'div',
    size,
    ...other
  } = props;

  const baseCls = 'sui-flex sui-flex-col sui-relative sui-bg-primary sui-text-primary sui-shadow-md sui-self-center sui-rounded-base sui-pt-4 sui-pb-6 sui-overflow-y-auto';

  const sizeCls = classNames('md:sui-my-8 md:sui-max-h-[calc(100%-64px)]', {
    'sui-max-h-[calc(100%-32px)] sui-my-4 sui-col-start-1 sui-col-end-13 md:sui-col-start-5 md:sui-col-end-9': size === 'sm',
    'sui-max-h-[calc(100%-32px)] sui-my-4 sui-col-start-2 sui-col-end-12 md:sui-col-start-4 md:sui-col-end-10': size === 'md',
    'sui-h-screen sui-col-span-12 md:sui-col-start-3 md:sui-col-end-11 2xl:sui-col-start-2 2xl:sui-col-end-12': size === 'lg'
  });

  return (
    <RootComponent
      className={`${baseCls} ${sizeCls}`}
      ref={ref}
      {...other}
    />
  );
});

/**
 * Modals focus a user’s attention on a simple task or action that requires user input.
 *
 * `Modal` can be built using composable subcomponents such as `ModalHeader`, `ModalBody` and `ModalFooter`.
 * Button components can be used within the `ModalFooter` to trigger actions.
 *
 * Related components: [ModalHeader](#modalheader), [ModalBody](#modalbody), [ModalFooter](#modalfooter),
 * [Button](#button).
 *
 * Usage:
 *
 * ```jsx
 * import { Modal } from '@one-thd/sui-atomic-components';
 * ```
 */
export const Modal = React.forwardRef((props, ref) => {

  const {
    'aria-describedby': ariaDescribedby,
    'aria-labelledby': ariaLabelledby,
    children,
    ModalRootProps = {},
    onClose,
    open,
    size = 'md',
    ...other
  } = props;

  const gridClasses = classNames('sui-grid sui-grid-cols-12 sui-gap-4 md:sui-mx-6 md:sui-gap-6 lg:sui-mx-8 lg:sui-gap-8 sui-h-full sui-self-center sui-outline-none', {
    'sui-mx-0': size === 'lg',
    'sui-mx-4': size !== 'lg'
  });

  return (
    <ModalBase
      closeAfterTransition
      onClose={onClose}
      open={open}
      ref={ref}
      {...other}
    >
      <Fade in={open} classes={gridClasses}>
        <ModalRoot
          aria-describedby={ariaDescribedby}
          aria-labelledby={ariaLabelledby}
          size={size}
          {...ModalRootProps}
        >
          {children}
        </ModalRoot>
      </Fade>
    </ModalBase>
  );
});

Modal.displayName = 'Modal';

Modal.propTypes = {
  /**
   * The id(s) of the element(s) that describe the modal.
   */
  'aria-describedby': PropTypes.string,
  /**
   * The id(s) of the element(s) that label the modal.
   */
  'aria-labelledby': PropTypes.string,
  /**
   * Modal children, usually the included sub-components.
   */
  children: PropTypes.node,
  /**
   * Props applied to container component.
   * @default {}
   */
  ModalRootProps: PropTypes.object,
  /**
   * If true, the modal is shown.
   */
  open: PropTypes.bool.isRequired,
  /**
   * Function to handle a close event for modal
   */
  onClose: PropTypes.func,
  /**
   * Size of the modal
   * @default 'md'
   */
  size: PropTypes.oneOf(['sm', 'md', 'lg'])
};

Modal.defaultProps = {};
