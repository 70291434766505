import React from 'react';
import {
  bool, func, oneOfType, shape, string
} from 'prop-types';
import { FormBaseTextFields } from './FormBaseTextFields';

const FormEmail = (props) => {
  const {
    name, defaultValue, label, onChange, onValidate, optional, placeholder, required
  } = props;

  return (
    <FormBaseTextFields
      name={name}
      defaultValue={defaultValue}
      label={label}
      onChange={onChange}
      onValidate={onValidate}
      optional={optional}
      placeholder={placeholder}
      required={required}
      type="email"
    />
  );
};

FormEmail.displayName = 'FormEmail';

FormEmail.propTypes = {
  name: string.isRequired,
  defaultValue: string,
  label: string,
  onChange: func,
  onValidate: oneOfType([
    func,
    shape({})
  ]),
  optional: bool,
  placeholder: string,
  required: bool,
};

FormEmail.defaultProps = {
  defaultValue: '',
  label: 'email',
  onChange: null,
  onValidate: null,
  optional: false,
  placeholder: 'email',
  required: false,
};

export { FormEmail };
